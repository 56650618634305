@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");



.socialfixed {
  background: transparent;
  position: fixed;
  z-index: 10;
  top: 60px;
  left: 2.8rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  height: 90%;
  padding: 8rem 0;
  /* pointer-events: none; */
  color: #fff;
  align-items: center;
  justify-content: space-evenly;
}

.insta{
    max-width: 3%;
}

@media only screen and (max-width: 767px){
  .socialfixed{
    display: none;
  }
}


