@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.BlogHeading {
  font-size: 24px;
  color: #f19e38;
  text-align: center;
  margin-top: 10%;
  font-family: "poppins";
}

@media only screen and (max-width: 767px){
  .BlogHeading{
    margin-top: 20%;
    font-size: 20px;
  }
}



