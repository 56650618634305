.mainimage {
  max-width: 100%; /* Ensure the image doesn't exceed its parent container */
  height: 100vh; /* Maintain the image's aspect ratio */
}

/* .heroall {
  margin-top: 65px;
} */

/* Media query for screens with a maximum width of 768px (typical for mobile devices) */
/* Mobile */

.mainimage {
  width: 100%; /* Make the image fill the width of the viewport */
}
.mainimagemobile {
  display: none;
}

@media (max-width: 767px) {
  .mainimage {
    width: 100%; /* Make the image fill the width of the viewport */
    display: none;
  }
  .mainimagemobile {
    display: block;
    width: 100%;
  }
}
