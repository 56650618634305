.Bookfilters {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}

.fil {
  font-size: 34px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #000;
  margin-bottom: 40px;
}

.category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}

.category i {
  color: #f19e38;
  font-size: 20px;
}

.category h1 {
  font-size: 24px;
  color: #000;
  font-weight: 500;
}

.fa-plus {
  color: #f19e38;
  font-size: 12px;
}

.Loadmore {
  color: #f19e38;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  margin-bottom: 12px;
  width: 100%;
  text-align: center;
}

.refine {
  background: #f19e38;
  color: #fff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-bottom: 12px;
}
.filter {
  background: #fff;
  border: 1px solid #f0e4f4;
  color: #636363;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.filtersbooks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1300;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
  color: #f19e38;
  /* margin-left: 50px; */
  padding: 10px;
}

/* Add a class for the open state */
.open {
  width: 280px;
  padding: 20px;
}

/* Media query for small screens */
@media screen and (max-height: 767px) {
  .sidenav {
    padding-top: 80px;
  }
  .sidenav a {
    font-size: 18px;
  }
  .openButton {
    display: block;
    font-size: 18px;
    color: #f19e38;
    padding: 0 20px;
  }
  .open{
    width: 320px;
  }

}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .openButton {
    display: none;
  }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .openButton {
    display: none;
  }
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  .openButton {
    display: none;
  }
}
