@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.overallmission {
  position: relative;
  background-image: url("../../public/images/all4.svg");
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  /* height: 1720px; */
}

/* .welcome {
  background-image: url("../../public/images/welcome.svg");
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  height: 55vw;
  position: relative;
} */

/* .welcome {
  position: relative;
  max-width: 100%;
  height: 55vw;
}

.welcome::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../public/images/welcome.svg");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1; 
} */

.welcomeheaing {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 15%;
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.kamaimg {
  max-width: 2%;
  position: absolute;
  left: 34%;
  top: 23%;
}

.paatiimg {
  max-width: 20%;
  position: absolute;
  right: 10px;
  top: 165px;
}

/* .mission {
  background-image: url("../../public/images/ourmission.svg");
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  height: 55vw;
} */

/* .mission {
  position: relative;
  max-width: 100%;
  height: 55vw;
  overflow: hidden; 
}

.mission::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../public/images/ourmission.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -100px;
  z-index: -1; 
} */

.welcomeheaing {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 12%;
  font-size: 26px;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
}

.subcontent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0%;
  font-size: 20px;
  line-height: 40px;
  font-family: "Poppins", sans-serif;
  padding: 0 20%;
}

.kamaimg {
  max-width: 2%;
  position: absolute;
  left: 32%;
  top: 8%;
}

/* .paatiimg {
  max-width: 25%;
  position: absolute;
  right: 14px;
  top: 135px;
} */

.crow {
  max-width: 90%;
  margin: auto;
  padding-top: 310px;
}

.welcomecolumn1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcomecolumn2 {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  margin-top: 100px;
  padding-top: 0%;
}

.welcomevision {
  background: #f19e38;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  border-radius: 30px;
  margin-bottom: 20px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
}

.welcomevisiontitle {
  font-size: 28px;
  color: #000;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
}

.subcontentvision {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
}

.fox {
  /* max-width: 50%; */
  /* margin: auto; */
  /* padding-top: 30%; */
  max-width: 70%;
  /* width: 100%; */
  /* margin: auto; */
  padding-top: 5%;
}

.welcomecolumn1 {
  display: flex;
  justify-content: start;
  align-items: center;
}

.welcomecolumn2fox {
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
  padding-top: 8%;
  margin-left: -6%;
}

.welcomemissionfox {
  background: #fdf3e2;
  color: #f19e38;
  font-size: 26px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  border-radius: 30px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  padding: 10px;
}

.welcomevisiontitlefox {
  font-size: 28px;
  color: #000;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
}

.subcontentvisionfox {
  font-size: 18px;
  color: #000;
  /* font-weight: 500; */
  text-align: end;
  font-family: "Poppins", sans-serif;
  margin-top: 8px;
}

.welcomecolumn2 {
  margin-top: 60px !important;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .mission {
    margin: auto;
  }
  .overallmission {
    background-image: url("../../public/images/foxandcrow.svg");
    /* width: 100%; */
    background-position: center;
    background-size: cover;
    height: 65rem;
  }
  .welcomecolumn {
    margin-top: 15% !important;
    /* margin-top: 10% !important; */
  }

  /* .welcome {
    position: relative;
    max-width: 100%;
    height: 85vw;
  }

  .welcome::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../public/images/mobilewelcomebg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1; 
  }  */
  .paatiimg {
    display: none;
  }

  .welcomeheaing {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }
  .subcontent {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    line-height: 24px;
    padding: 0;
  }
  .kamaimg {
    max-width: 3%;
    left: 11%;
    top: 7%;
  }
  /* .mission {
    position: relative;
    max-width: 100%;
    height: 80vh;
    overflow: hidden;
  }

  .mission::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../public/images/mobilevision.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -50px;
    z-index: -1;
  } */
  .welcomecolumn1 {
    display: none;
  }
  .welcomecolumn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25% !important;
  }
  .subcontentvision {
    text-align: center;
  }
  .welcomevision {
    font-size: 16px;
    padding: 5px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
  }

  .welcomevisiontitle {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
  }

  .subcontentvision {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    line-height: 24px;
  }
  .welcomemissionfox {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
  }
  .welcomevisiontitlefox {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }
  .subcontentvisionfox {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    text-align: center;
    line-height: 24px;
  }
  .welcomecolumn2fox {
    margin-top: 8% !important;
    /* margin-top: 0% !important; */

    align-items: center;
    margin: auto;
  }
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .overallmission {
    background-image: url("../../public/images/foxandcrow.svg");
    /* width: 100%; */
    background-position: center;
    background-size: cover;
    height: 120rem;
  }
  .crow {
    padding-top: 200px;
  }
  .welcomevision {
    font-size: 28px;
    font-family: "Poppins", sans-serif;
  }
  .welcomeheaing {
    font-size: 24px;
  }
  .kamaimg {
    left: 23%;
    top: 10%;
  }
  .paatiimg {
    display: none;
  }
  .crow {
    display: none;
  }
  .fox {
    display: none;
  }
  .subcontent {
    padding: 0 10%;
    font-size: 20px;
  }
  .mission {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .missionfox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .welcomecolumn {
    margin-top: 15% !important;
    margin: auto;
  }
  .welcomecolumn2 {
    margin-top: 35% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
  }
  .welcomecolumn2fox {
    margin-top: 35% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
  }
  .subcontentvisionfox {
    text-align: center;
  }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .overallmission {
    height: 1480px;
  }
  .crow {
    max-width: 100%;
    width: 80%;
    /* height: -webkit-fill-available; */
    padding-top: 25%;
  }
  .welcomevision {
    font-size: 26px;
    font-family: "Poppins", sans-serif;
  }
  .welcomecolumn1 {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

/* Larger screens */
@media only screen and (min-width: 1440px) {
  .overallmission {
    height: 1750px;
  }
  .crow {
    max-width: 100%;
    width: 70%;
    /* height: -webkit-fill-available; */
    padding-top: 35%;
  }
  .welcomevision {
    width: 30%;
    font-size: 28px;
    font-family: "Poppins", sans-serif;
  }
  .welcomeheaing {
    font-size: 40px;
    font-family: "Poppins", sans-serif;
  }
  .kamaimg {
    left: 26%;
  }
  .subcontent {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
  }
  .paatiimg {
    right: 4%;
    top: 15%;
    /* right: 40px;
    top: 170px; */
  }
  .welcomevisiontitle {
    font-size: 34px;
    font-family: "Poppins", sans-serif;
  }
  .subcontentvision {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
  }
  .welcomemissionfox {
    width: 35%;
    font-size: 28px;
    font-family: "Poppins", sans-serif;
  }
  .welcomevisiontitlefox {
    font-size: 26px;
    font-family: "Poppins", sans-serif;
  }
  .subcontentvisionfox {
    font-size: 24px;
    font-family: "Poppins", sans-serif;
  }
  .welcomecolumn2fox {
    padding-top: 10%;
  }
  .missionfox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .mission {
    margin-top: 10% !important;
  }
  .fox {
    /* max-width: 100%; */
    /* margin: auto; */
    /* width: 100%; */
    /* padding-top: 20%; */
    max-width: 80%;
    padding-top: 0%;
    width: 100%;
  }
  .welcomecolumn1 {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

@media (min-width: 2048px) and (max-width: 4096px) {
  .overallmission {
    height: 3500px;
  }
  .crow {
    max-width: 100%;
    width: 65%;
    /* height: -webkit-fill-available; */
    padding-top: 750px;
  }
  .welcomevision {
    width: 28%;
    font-size: 60px;
    font-family: "Poppins", sans-serif;
  }
  .welcomeheaing {
    font-size: 50px;
    font-family: "Poppins", sans-serif;
  }
  .subcontent {
    font-size: 32px;
    line-height: 60px;

    font-family: "Poppins", sans-serif;
  }
  .paatiimg {
    right: 0px;
    top: 659px;
  }
  .welcomevisiontitle {
    font-size: 60px;
    font-family: "Poppins", sans-serif;
  }
  .subcontentvision {
    font-size: 44px;
    font-family: "Poppins", sans-serif;
  }
  .welcomemissionfox {
    width: 40%;
    font-size: 60px;
    font-family: "Poppins", sans-serif;
  }
  .welcomevisiontitlefox {
    font-size: 56px;
    font-family: "Poppins", sans-serif;
  }
  .subcontentvisionfox {
    font-size: 45px;
    padding-left: 70px;
    font-family: "Poppins", sans-serif;
  }
  .welcomecolumn2fox {
    padding-top: 15%;
  }
  .missionfox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .fox {
    max-width: 100%;
    /* margin: auto; */
    width: 62%;
    padding-top: 240px;
  }
  .welcomecolumn2 {
    margin-top: 20% !important;
  }
}
