.CheckOutTable {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  background: #f19e38;
  color: #fff;
  font-family: "Poppins", sans-serif;
  width: 70%;
  border-radius: 5px;
  margin-bottom: 10px;
}

.tablehead1 {
  width: 30%;
  margin-bottom: 0;
  padding: 10px;
}

.tablehead2 {
  width: 15%;
  margin-bottom: 0;
  padding: 10px;
}
.tablehead3 {
  width: 15%;
  margin-bottom: 0;
  padding: 10px;
}
.tablehead4 {
  width: 15%;
  margin-bottom: 0;
  padding: 10px;
}

.CheckOutTablerow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  background: #fdf3e2;
  color: #f19e38;
  font-family: "Poppins", sans-serif;
  width: 70%;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* .CheckOutoversize {
  overflow-y: scroll;
  max-height: 300px;
  
} */

.tableheadrow1 {
  width: 26%;
  margin-bottom: 0;
  /* padding: 10px; */
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.tableheadrow2 {
  width: 15%;
  margin-bottom: 0;
  padding: 10px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-align: left;
}
.tableheadrow3 {
  width: 15%;
  margin-bottom: 0;
  padding: 10px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-align: left;
}
.tableheadrow4 {
  width: 13%;
  margin-bottom: 0;
  padding: 10px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.qty {
  background: #fdf3e2;
  padding: 5px 25px;
  border-radius: 5px;
  width: 15%;
}

.minus {
  font-size: 14px;
  color: #f19e38;
  margin-right: 10px;
  cursor: pointer;
}

.count {
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #000;
}

.plus {
  font-size: 14px;
  color: #f19e38;
  margin-left: 10px;
  cursor: pointer;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .CheckOutTable {
    width: 100%;
  }
  .tablehead1 {
    width: 20%;
  }
  .tablehead2 {
    width: 40%;
  }
  .tablehead3 {
    width: 20%;
  }
  .tablehead4 {
    width: 30%;
  }
  .CheckOutTablerow {
    width: 100%;
  }
  .tableheadrow1 {
    width: 20%;
  }
  .tableheadrow2 {
    width: 20%;
  }
  .tableheadrow3 {
    width: 30%;
  }
  .tableheadrow4 {
    width: 30%;
  }
  .qty{
    padding: 5px 20px;
    width: 50%;
  }
}
