@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.BookViewMore {
  padding: 30px;
}

.maybe {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}


.viewmore{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #F19E38;
}

.arrow{
    margin-left: 10px;
    color: #F19E38;
}


/* Mobile */
@media only screen and (max-width: 767px) {
  .viewmore{
    font-size: 12px;
    font-family: "Poppins", sans-serif;
  }
  .maybe{
    font-size: 14px !important;
    font-family: "Poppins", sans-serif;

  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
}
