#modal-modal-title{
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
}

.facebook{
  max-width: 25%;
}

.facebooklink{
  display: flex;
  justify-content: center;
  align-items: center;
}

.or{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
}