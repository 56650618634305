@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.explore {
  /* padding: 15px 0; */
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.title1 {
  margin-top: 23px;
  color: #000;
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.title2 {
  color: #000;
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.spark {
  max-width: 8%;
  position: absolute;
  top: -22px;
  right: 351px;
}

.magicalpara {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.explorebtn {
  background: #fff;
  font-size: 18px;
  text-align: center;
  margin-top: 50px;
  padding: 10px 0px;
  padding-left: 10px;
  border: 2px solid #000;
  color: #000;
}

.overallexpolre {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowouter {
  background: #000;
  padding: 12px;
  margin-left: 15px;
}

.arrow {
  max-width: 50%;
}

.Book {
  max-width: 70%;
}

.bookimg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.magicalpara2 {
  display: none;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .title1 {
    font-size: 20px;
  }
  .title2 {
    font-size: 24px;
    text-align: center;
  }
  /* .spark {
    top: -15px !important;
    right: 52px !important;
    max-width: 14% !important;
  } */
  .magicalpara {
    display: none;
  }
  .magicalpara2 {
    display: block;
    font-size: 14px;
    text-align: center;
  }
  .Book {
    max-width: 100%;
  }
  .explorebtn {
    margin-top: 20px;
  }
}

/* Tablet */

@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .spark {
    max-width: 10% !important;
    position: absolute;
    top: -30% !important;
    right: 22% !important;
  }
  .title1 {
    font-size: 30px;
  }
  .title2 {
    font-size: 30px;
  }
}

/* @media only screen and (min-width: 768px) and (max-width: 1023px) {
  
} */

/* Laptop */
@media only screen and (max-width: 1024px) {
  .spark {
    max-width: 7%;
    position: absolute;
    top: -40%;
    right: 30%;
  }
}

@media only screen and (max-width: 1500px) {
  .spark {
    max-width: 7%;
    position: absolute;
    top: -30%;
    right: 28%;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1600px) {
  .spark {
    max-width: 7%;
    position: absolute;
    top: -40%;
    right: 30%;
  }
}

@media (min-width: 2048px) and (max-width: 4096px) {
  .spark {
    max-width: 5%;
    position: absolute;
    top: -53px;
    right: 36%;
  }
  .title1 {
    font-size: 50px;
  }
  .title2{
    font-size: 52px;
  }
  .magicalpara{
    font-size: 26px;
  }
}

/* Larger screens */
/* @media only screen and (min-width: 1366px) {
} */
