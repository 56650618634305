@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.Testimonials {
  background: #fdf3e2;
  padding: 80px;
}

.Testtitle {
  font-size: 28px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #000;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .Testimonials{
    padding: 40px;
  }
 
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 979px){
  .Testimonials{
    padding: 40px;
  }
  .Testtitle{
    font-size: 24px;
  }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
}
