@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

/* Styles for the navigation bar */
.navbar {
  width: 100%;
  background-color: #ddddddb5;
  z-index: 1000;
  height: 80px;
  /* font-family: "Poppins", sans-serif; */
}
@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .navbar {
    position: relative;
    background-color: transparent;
  }

  body {
    padding-top: 0;
  }
  .logo {
    width: 50px;
    height: 70px;
  }
  .collapse.navbar-collapse {
    position: absolute;
    top: 100%; /* Adjust as needed */
    right: 0;
    left: 0;
    background-color: #000;
  }
  .nav-item {
    width: 100%;
    color: white;
    margin: 5px 0px;
  }
}

.navbar-brand {
  margin-left: 30px;
}
.corner {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.right {
  color: rgba(100, 100, 100, 1);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin: 0 18px;
  font-weight: 500;
}

.nav-item .nav-link {
  color: rgba(100, 100, 100, 1);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin: 0 18px;
  font-weight: 500;
}

.blackColor {
  color: #000; /* Black color for the text */
}

.navDropdown {
  cursor: pointer;
}

/* Center-align the button */
.btn {
  border-radius: 20px;
}

/* Styles for the dropdown menus */
.dropdown-menu {
  background-color: #f1f1f1;
}

/* Center text inside the section */
.text-center {
  text-align: center;
}

/* Center text inside h1 and p with separate class names */
.center-text {
  text-align: center;
}

/* Styles for the collapsed navbar */
.collapse.navbar-collapse {
  justify-content: flex-end;
}

/* Styles for the navigation links */
.nav-item {
  display: inline-block;
}

.nav-link {
  font-weight: 600;
}

/* Styles for the dropdown items */
.dropdown-item {
  color: #000; /* Black color for the dropdown items */
}

.dropdown-divider {
  background-color: #000; /* Black color for the dropdown divider */
}

/* Style for the "Contact us" button */
.btn.btn-dark {
  border-radius: 20px;
}

.nav {
  /* margin-right: 270px;
  gap: 30px; */
  font-size: 18px;
}

.right {
  font-size: 18px;
  text-decoration: none;
  font-weight: 600;
  margin-right: 10px;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .nav-item {
    width: 100%;
    color: white;
  }
  .nav-link:hover {
    color: white !important;
  }
  .nav-item a {
    padding: 1px 15px;
    font-size: 14px;
  }

  .corner {
    padding: 0px 35px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .navbar-brand {
    position: absolute;
    top: 0;
    right: 0;
  }

  .collapse.navbar-collapse {
    position: absolute;
    top: 100%; /* Adjust as needed */
    right: 0;
    left: 0;
    background-color: #000;
  }

  .btn.btn-dark {
    display: block;
    margin: 0 auto;
    margin-top: 20px; /* Adjust as needed */
  }
}

@media (max-width: 768px) {
  .cart {
    /* Adjust the styles for smaller screens */
    text-align: left; /* Center the link and image */
    display: block; /* Make it a block element for full width */
    margin: 10px; /* Add margin for spacing */
  }

  .cart img {
    width: 50px; /* Adjust the image width for smaller screens */
  }
}

.logo {
  width: 40px;
  height: 60px;
}

@media (min-width: 2048px) and (max-width: 4096px){
  .nav-item .nav-link{
    font-size: 30px;
  }
}
