.pro {
  padding: 10px 90px;
}

.orderimage {
  max-width: 50%;
  border-radius: 10px;
  margin: auto;
}

.cardview {
  padding: 10px;
}

.date {
  margin-left: 5px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.mulitorders {
  margin-top: 40px;
}

.placed {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.title {
  font-size: 20px;
  margin-left: 10px;
  font-family: "Poppins", sans-serif;
  text-align: justify;
}

.author {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-left: 10px;
  text-align: justify;
  margin-bottom: 0;
}

.price {
  color: #f19e38;
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  margin-bottom: 0;
}

.qty {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  display: inline-block;
  background: #fdf3e2;
  padding: 10px;
  border-radius: 5px;
}

.number {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
}

.deliverycharge{
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
}

.tax{
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin-left: 10px; 
}

/* order Tracking */

.container{
  display: flex;
  justify-content: space-between;
  /* background-color: #F8F8F8; */
  /* padding: 5px; */
}

.hhGrayBox {
	/* background-color: #F8F8F8; */
	margin-bottom: 15px;
	padding:5px 12px;
  margin-top: 5px;
  width: 50vw;
  border-radius: 5px;
}
.pt45{padding-top:20px;}
.orderTracking{
	text-align: center;
	width: 33.33%;
	position: relative;
	display: block;
}
.orderTracking .isComplete{
	display: block;
	position: relative;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	border: 0px solid #AFAFAF;
	background-color: #f7be16;
	margin: 0 auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
	z-index: 2;
}
.orderTracking .isComplete:after {
	display: block;
	position: absolute;
	content: '';
	height: 14px;
	width: 7px;
	top: -2px;
	bottom: 0;
	left: 5px;
	margin: auto 0;
	border: 0px solid #AFAFAF;
	border-width: 0px 2px 2px 0;
	transform: rotate(45deg);
	opacity: 0;
}
.orderTracking.completed .isComplete{
	border-color: #27aa80;
	border-width: 0px;
	background-color: #27aa80;
}
.orderTracking.completed .isComplete:after {
	border-color: #fff;
	border-width: 0px 3px 3px 0;
	width: 7px;
	left: 11px;
	opacity: 1;
}
.orderTracking p {
	color: #A4A4A4;
	font-size: 12px;
	margin-top: 8px;
	margin-bottom: 0;
	line-height: 20px;
}
.orderTracking p span{font-size: 12px;}
.orderTracking.completed p{color: #000;}
.orderTracking::before {
	content: '';
	display: block;
	height: 3px;
	width: calc(100% - 40px);
	background-color: #f7be16;
	top: 13px;
	position: absolute;
	left: calc(-50% + 20px);
	z-index: 0;
}
.orderTracking:first-child:before{display: none;}
.orderTracking.completed:before{background-color: #27aa80;}


@media (max-width: 768px) {
  .title {
    margin-top: 20px;
    font-size: 16px;
  }
  .pro {
    padding: 10px 25px;
  }
  
  .author {
    font-size: 12px;
  }
  .hhGrayBox{
    width: 75vw;
  }
}
