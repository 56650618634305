.payment {
  text-align: center;
  margin-top: 25px;
}

.pay{
    text-align: center;
}

.go{
    margin-bottom: 5%;
}
