@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.newselection {
  border: 2px solid #f19e38;
  padding: 6px 20px;
  border-radius: 8px;
  margin-right: 10px;
  color: #f19e38;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .newselection{
    font-size: 16px;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
}


