@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

/* .titlebook {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
} */

.bookdetailoverall {
  margin-top: 10%;
  margin-bottom: 10%;
}
.detailsbook {
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 10px;
  border: none;
  outline: none;
}
.gridItem {
  height: inherit;
  background-color: #4d4d4d;
  list-style-type: none;
  padding: 15px;
  color: #fff;
  font-size: 14px;
  text-align: left;
  border: none;
  outline: none;

  font-family: "Poppins", sans-serif;
}
.gridItemWhite {
  background-color: #fcf8fd;
  list-style-type: none;
  padding-left: 0;
  /* border-radius: 0px 10px 10px 0px; */
  height: inherit;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  padding: 15px 45px;
  color: #4d4d4d;
  border: none;
  outline: none;
  font-size: 14px;
  text-align: left;
}
/* .details2 {
  background-color: #fcf8fd;
  list-style-type: none;
  padding-left: 0;
  /* width: 70%; */
/* border-radius: 0px 10px 10px 0px;
  height: inherit;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
} */

.title {
  margin: 0;
  background-color: #4d4d4d;
}

.titlename {
  margin: 0;
}

.details2 li {
  padding: 15px 45px;
  color: #4d4d4d;
  font-size: 14px;
  text-align: left;
  font-family: "Poppins", sans-serif;
}
.details {
  background-color: #4d4d4d;
  list-style-type: none;
  padding-left: 0;
  width: 30%;
  border-radius: 10px 0px 0px 10px;
  height: inherit;
}

.details li {
  padding: 15px;
  color: #fff;
  font-size: 14px;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 600px) {
  .details {
    width: 100%;
  }
  .details2 {
    width: 100%;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .bookdetailhalf {
    margin-bottom: 10%;
    font-family: "Poppins", sans-serif;
  }
  /* .details2 li {
    padding: 19px 45px;
    font-family: "Poppins", sans-serif;
  } */
  .gridItemWhite {
    padding: 19px 45px;
    font-family: "Poppins", sans-serif;
  }
}
