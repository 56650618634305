@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.gallerycaro {
  position: relative;
}

.items {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
}

.items .item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.3);
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
  transition: all 300ms ease-in-out;
  z-index: -1;
  opacity: 0;
}

.item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item.active {
  opacity: 1;
  z-index: 99;
  box-shadow: 0px 0px 30px -35px rgba(0, 0, 0, 0.75);
}

.item.prev {
  z-index: 2;

  opacity: 1;
  transform: translate(-170%, -50%) scale(0.8);
}

.item.next {
  z-index: 2;
  opacity: 1;
  transform: translate(75%, -50%) scale(0.8);
}

.items .button-container {
  position: absolute;
  top: 10px;
  left: 50%;

  transform: translate(-50%, -50%);
  width: 275px;
  /* z-index: 100; */
}

.button-container .button {
  color: #171006de;
  font-size: 32px;
  cursor: pointer;
  position: relative;
  opacity: 0.75;
  transition: all 300ms ease-in-out;
  font-family: "Poppins", sans-serif;
}

/* .button-container .button:hover {
  opacity: 1;
  background-color: #f69e078d;
} */

.button-container .button:before {
  content: "";
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 50px;
  height: 50px;
  /* background-color: #d2d2d2; */
  border-radius: 50%;
  z-index: -99;
}

.button-container .button:nth-child(1) {
  float: left;
  margin-top: 120%;
}

.button-container .button:nth-child(2) {
  float: right;
  margin-top: 120%;
}
.demo1 ul {
  list-style-type: none;
  position: relative;
  padding: 0;
}

.overallcarosuel {
  position: relative;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../public/images/gallery.svg");
  /* margin-top: 30px; */
  padding-bottom: 30px;
  height: 38rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0%;
}

.gallerycapturing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: -38%;
  left: 37%;
}

.Grap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 80px;
  background: #f19e38;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  padding: 10px;
  border-radius: 30px;
  width: 15%;
}

.demo1 {
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  position: absolute;
  right: 65px;
  top: 60px;
}

.demo1 li {
  max-width: 5%;
}

.gallery {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  z-index: 2;
  position: relative;
  cursor: pointer;
}

.gallery::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background: #ffffff94;
  opacity: 0.3;
  z-index: -1;
}

.fa-angle-left {
  color: #fff;
  width: 50px;
  height: 50px;
  background-color: #d2d2d2;
  border-radius: 50%;
  /* padding: 11px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.fa-angle-right {
  color: #fff;
  width: 50px;
  height: 50px;
  background-color: #d2d2d2;
  border-radius: 50%;
  /* padding: 11px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.fa-angle-right:hover {
  background: #f19e38;
  color: #fdf3e2;
}
.fa-angle-left:hover {
  background: #f19e38;
  color: #fdf3e2;
}

.awards {
  background-image: url(../../public/images/awards.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  cursor: pointer;
  margin-bottom: 10px;
}

.awards::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #d9d9d947;
  border-radius: 50%;
}

.contentawards {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 24px;
  left: 7px;
  text-align: center;
  margin-bottom: 0;
  z-index: 10;
}
.events {
  background-image: url(../../public/images/events.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  cursor: pointer;
  margin-bottom: 10px;
}
.events::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #d9d9d947;
  border-radius: 50%;
}

.contentawards2 {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 24px;
  left: 11px;
  text-align: center;
  margin-bottom: 0;
  z-index: 10;
}
.books {
  background-image: url(../../public/images/books.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  cursor: pointer;
  margin-bottom: 10px;
}

.books::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #d9d9d947;
  border-radius: 50%;
}

.contentawards3 {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 24px;
  left: 13px;
  text-align: center;
  margin-bottom: 0;
  z-index: 10;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .overallcarosuel {
    margin-top: 65px;
    padding-bottom: 30px;
    /* height: 52rem; */
    overflow: hidden;
    margin-bottom: 0%;
    background: #fff;
  }
  .items .item {
    width: 200px;
    height: 200px;
  }
  .demo1 {
    display: none;
  }
  .gallerycapturing {
    left: 30px;
    /* margin-top: 40px; */
    top: 0;
  }
  .gallerycapturing p {
    text-align: center;
    font-size: 18px;
  }
  .Grap {
    width: 15%;
    /* display: none; */
    bottom: 118px;
    color: #000;
  }
  .items {
    top: 23%;
  }
  .item.prev {
    transform: translate(-134%, 43%) scale(0.8);
  }
  .item.next {
    transform: translate(33%, 43%) scale(0.8);
  }
  .items .button-container {
    transform: translate(-50%, -20%);
    top: 50px !important;
  }
  .button-container {
    /* display: flex;
    justify-content:center ; */
  }
  #gallery {
    font-size: 24px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .overallcarosuel {
    margin-top: 65px;
    padding-bottom: 30px;
    /* height: 52rem; */
    overflow: hidden;
    margin-bottom: 0%;
    background: #fff;
  }
  .items .item {
    width: 200px;
    height: 200px;
  }
  .demo1 {
    display: none;
  }
  .gallerycapturing {
    left: 30px;
    /* margin-top: 40px; */
    top: 0;
  }
  .gallerycapturing p {
    text-align: center;
    font-size: 18px;
  }
  .Grap {
    width: 15%;
    /* display: none; */
    bottom: 118px;
    color: #000;
  }
  .items {
    top: 23%;
  }
  .item.prev {
    transform: translate(-134%, 43%) scale(0.8);
  }
  .item.next {
    transform: translate(33%, 43%) scale(0.8);
  }
  .items .button-container {
    transform: translate(-50%, -20%);
    top: 50px !important;
  }
  .button-container {
    /* display: flex;
    justify-content:center ; */
  }
  #gallery {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1366px) {
  .gallerycapturing {
    top: -40%;
  }
  #gallery {
    font-size: 40px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
  }
}

@media only screen and (min-width: 1500px) {
  .overallcarosuel {
    height: 40rem !important;
  }
}

@media (min-width: 2048px) and (max-width: 4096px) {
  .items .item {
    width: 450px;
    height: 450px;
  }
  .Grap {
    bottom: -30px;
    font-size: 30px;
    width: 10%;
  }
  .items .button-container {
    top: 177px;
  }
  .overallcarosuel {
    margin-top: 30px;
  }
  .demo1{
    top: -40px;
  }
  .awards {
    width: 150px;
    height: 150px;
  }
  .contentawards {
    top: 64px;
    left: 44px;
  }
  .events {
    width: 150px;
    height: 150px;
  }
  .contentawards2 {
    top: 64px;
    left: 44px;
  }
  .books {
    width: 150px;
    height: 150px;
  }
  .contentawards3 {
    top: 64px;
    left: 44px;
  }
}
