.BooksDesc {
  margin-top: 0%;
}

@media only screen and (max-width: 767px) {
  .BooksDesc {
    margin-top: 10%;
  }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  
}