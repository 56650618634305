@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.movebackmove {
  background: linear-gradient(
    90deg,
    #fbf7fc 22.24%,
    rgba(251, 247, 252, 0) 100%
  );
}

.moveback {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 20px;
  margin: 0 25px;
}

.home {
  font-size: 18px;
  font-family: "poppins";
  font-weight: 500;
  color: #f19e38;
  text-decoration: none;
  margin-right: 5px;
}

.slash {
  font-size: 18px;
  font-family: "poppins";
  font-weight: 600;
  color: #f19e38;
  margin-right: 10px;
}

.backblog {
  font-size: 16px;
  font-family: "poppins";
  font-weight: 500;
  color: #f19e38;
  text-decoration: none;
}

.side {
  display: none;
}

@media only screen and (max-width: 767px) {
  .home {
    font-size: 12px;
  }
  .slash {
    font-size: 12px;
  }
  .backblog {
    font-size: 12px;
  }
  .movebackmove {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .side {
    display: block;
  }
  .moveback {
    padding: 15px 0px;
  }
}
