.order {
  margin-top: 0%;
}

.load {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadmore {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  background: #fdf3e2;
  color: #f19e38;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 2%;
  font-weight: 600;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .order {
    margin-top: 10%;
  }
}
