@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.overallbook {
  padding: 20px 7px;
  margin: 0 25px;
}

.fil {
  font-size: 34px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #000;
  margin-bottom: 40px;
}

.Bookfilters {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}

.Bookshowcaseheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}

.category i {
  color: #f19e38;
  font-size: 20px;
}

.category h1 {
  font-size: 24px;
  color: #000;
  font-weight: 500;
}

.fa-plus {
  color: #f19e38;
  font-size: 12px;
}

.Loadmore {
  color: #f19e38;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  margin-bottom: 12px;
  width: 100%;
  text-align: center;
}

.refine {
  background: #f19e38;
  color: #fff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-bottom: 12px;
}
.filter {
  background: #fff;
  border: 1px solid #f0e4f4;
  color: #636363;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.filtersbooks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.booksheading {
  font-size: 34px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #000;
  margin-bottom: 10px;
}

.interpara {
  /* margin: 0; */
  color: #000;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

.gridviewimgs {
  margin-right: 10px;
  cursor: pointer;
  max-width: 18%;
}

.gridlistimgs {
  cursor: pointer;
  max-width: 18%;
}

.bookitems {
  margin-top: 50px;
  padding: 0 50px;
  position: relative;
}

.load {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadmore {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  background: #fdf3e2;
  color: #f19e38;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 5%;
  font-weight: 600;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .Bookfilters {
    display: none;
  }
  .Bookshowcaseheading {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .bookitems {
    padding: 0 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .overallbook {
    padding: 20px 7px;
    margin: 0 5px;
  }
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .fil {
    font-size: 24px;
  }
  .booksheading {
    font-size: 24px;
  }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
}
