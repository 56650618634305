.bookheading {
  font-size: 22px;
  padding: 0 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.ramya {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
}

.bookimg {
  max-width: 5%;
  border-radius: 50%;
  margin-right: 10px;
}

.outofstock {
  background: #fdf3e2;
  color: #f19e38;
  border-radius: 10px;
  padding: 0px 15px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.ramyasethuram {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.Vadai {
  font-size: 14px;
  margin-bottom: 20px;
  padding: 0 20px;
  font-family: "Poppins", sans-serif;
}

.amount {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
}

.addedbtn {
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.Available {
  padding: 0 20px;
}

.amount1 {
  font-size: 24px;
  color: #f19e38;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.amount2 {
  color: #636363;
  text-decoration: line-through;
  font-size: 14px;
  margin-left: 20px;
}

.amountpercentage {
  background: #ff7a00;
  padding: 0 6px;
  margin-left: 20px;
  border-radius: 5px;
}

.Available {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #000;
  font-weight: 500;
}

.wishlist {
  background-color: #f19e38;
  border-radius: 5px;
  border: none;
  margin-left: 15px;
  padding: 5px;
  color: #fff;
  text-decoration: none;
}

.heart {
  color: #fff;
  margin-right: 10px;
  font-size: 22px;
}

.qty {
  background: #fdf3e2;
  padding: 5px 25px;
  border-radius: 5px;
}

.minus {
  font-size: 14px;
  color: #f19e38;
  margin-right: 10px;
  cursor: pointer;
}

.count {
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #000;
}

.plus {
  font-size: 14px;
  color: #f19e38;
  margin-left: 10px;
  cursor: pointer;
}

.BookDescView2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.imgbook {
  /* max-width: 80%; */
  width: 250px;
  height: 350px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
  object-fit: cover;
}
.imgbook2 {
  /* max-width: 36%; */
  width: 250px;
  height: 350px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
  object-fit: cover;
}

.imgrowbook {
  /* max-width: 85%; */
  width: 100px;
  height: 80px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.addBtn {
  background-color: #f19e38;
  border-radius: 5px;
  border: none;
  margin-left: 15px;
  padding: 5px;
  color: #fff;
  text-decoration: none;
}

/* gallery */
.slider {
  width: 100vw;
  height: 100vh;
  /* overflow: hidden; */
  position: relative;
}
.slider .wrapper {
  height: 100vh;
  transition: 0.5s all linear;
  will-change: transform;
}
.slider .arrows {
  position: absolute;
  top: 50%;
  background: none;
  height: 60px;
  border: 0;
  cursor: pointer;
  transition: ease 0.3s all;
  outline: none;
}
.slider .arrows.prev {
  left: 0;
}
.slider .arrows.prev:hover {
  opacity: 0.7;
  left: -10px;
}
.slider .arrows.next {
  right: 0;
}
.slider .arrows.next:hover {
  right: -10px;
  opacity: 0.7;
}
.slider .dotsContainer {
  height: auto;
  margin: 0;
  padding: 0;
  position: absolute;
  width: auto;
  text-align: center;
  left: 50%;
  bottom: 9px;
  transform: translateX(-50%);
  z-index: 10;
  list-style-type: none;
}
.slider .dotsContainer li {
  display: inline-block;
  padding: 5px;
}
.slider .dotsContainer li.active button {
  color: #00d8ff;
}
.slider .dotsContainer li button {
  color: #fff;
  background-color: transparent;
  border: none;
}
.slider .dotsContainer li button:hover {
  text-decoration: none;
  opacity: 0.7;
  cursor: pointer;
}
.slider .togglePlay {
  background: transparent;
  border: none;
  height: auto;
  position: absolute;
  width: auto;
  right: 5%;
  bottom: 9px;
  color: #3d3d3d;
  z-index: 1000000;
}
.slider .togglePlay:hover {
  text-decoration: none;
  opacity: 0.7;
  cursor: pointer;
}
.slider .cancel {
  background: transparent;
  border: none;
  height: 2%;
  position: absolute;
  width: 4%;
  top: 5%;
  right: 35px;
  color: #3d3d3d;
  z-index: 1000000;
  cursor: pointer;
}
.slider .eachSlides {
  width: 100vw;
  height: 100vh;

  /* float: left;
  line-height: 100vh;
  font-size: 40vh;
  text-align: center;
  background-size: cover;
  background-position: center center;
  background-color: transparent; */
}

/* .gallerySlider{
  display: flex;
  width: 100vw !important;
  height: 100vh !important;
} */

/* .addBtn:hover {
  background-color: #f19e38;
  border-radius: 5px;
  border: none;
  padding: 5px;
  color: #fff;
  text-decoration: none;
} */

.BookDescView {
  padding-top: 10px;
}

@media only screen and (max-width: 767px) {
  .bookheading {
    padding: 0;
    font-size: 18px;
  }
  .ramya {
    padding: 0;
    font-size: 18px;
  }
  .Vadai {
    padding: 0;
    font-size: 14px;
  }
  .amount {
    padding: 0;
  }
  .addedbtn {
    padding: 0;
  }
  .Available {
    padding: 0;
  }
  .BookDescView {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
  .imgbook {
    /* max-width: 40%; */
    width: 100px;
    height: 200px;
    margin-right: 10px;
  }
  .imgbook2 {
    /* max-width: 43%; */
    width: 100px;
    height: 200px;
  }
  .imgrowbook {
    /* max-width: 95%; */
    width: 40px;
    height: 40px;
    margin-left: 0px;
  }
  .BookDescView2 {
    margin-bottom: 5%;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1600px) {
  .imgbook {
    width: 350px;
    height: 420px;
  }
  .imgbook2 {
    width: 350px;
    height: 420px;
  }
}
/* tablet */
@media only screen and (min-width: 768px) and (max-width: 979px) {
  .imgbook,
  .imgbook2 {
    width: 200px !important;
  }
  .Vadai {
    font-size: 13px;
  }
}
