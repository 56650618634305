.summary {
  background: url(../../../public/images/background.svg);
  /* background-color: #fdf3e2; */
 
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 50px;
  /* margin-top: 10%; */
  margin-bottom: 10%;
  border-radius: 20px;
  width: 70%;
  height: 100%;
  /* position: relative; */
}

.shoppingheading {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #000;
  margin-bottom: 20px;
  /* rgba(0, 0, 0, 0.5) */
}

.shoppingcontent {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: rgba(0, 0, 0, 0.5);
}

.shoppingcode {
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #000;
}

.promocodeinput {
  background: #f19e38;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  border: none;
  padding: 12px 50px;
  border-radius: 5px;
}

.text {
  position: relative;
}

.ticket {
  position: absolute;
  left: 10px;
  top: 2px;
  color: #fff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.btncode {
  margin-left: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f19e38;
  color: #fff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.checkoutbtn {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f19e38;
  color: #fff;
  font-size: 14px;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.Continue {
  font-family: "Poppins", sans-serif;
  color: #f19e38;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}

.subtotal {
  font-size: 14px;
  color: #755a7d;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.amount {
  font-size: 14px;
  color: #000;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
/* .CheckOutSummary1 {
  background: url(../../../public/images/background.svg);
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: inherit;
} */

/* mobile */

@media only screen and (max-width: 767px){
  .summary{
    width: 100%;
    padding: 40px;
    background-position: center;
  }
  .promocodeinput{
    width: 85%;
    margin-bottom: 20px;
  }
}
