@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.Subscribe {
  padding: 40px 0;
  background: #fdf3e2;
  position: relative;
}

.sub1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sub2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.newsletter {
  color: #f19e38;
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.emailinput {
  background: #f19e38;
  border: none;
  padding: 12px;
  border-radius: 5px;
  margin-right: 10px;
  width: 300px;
  position: relative;
  z-index: 1;
  /* opacity: 0.5; */
  color: #fdf3e2;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

.emailinput::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e8c59a;
  z-index: 2;
  opacity: 1;
}

input::placeholder {
  color: #fdf3e2;
}

.submit {
  background: #fff;
  color: #f19e38;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  padding: 12px;
  box-shadow: #cb9fdbc9 0px 1px 1px 0px, #cb9fdb9c 0px 50px 100px -5px,
    #cb9fdbb8 0px 30px 60px -10px;
}

.circleimg {
  position: absolute;
  left: 95px;
  top: 23px;
}

.circleElement {
  position: absolute;
  right: 0;
  bottom: -25px;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .emailinput {
    width: 180px;
  }
  .newsletter {
    font-size: 22px;
    margin-bottom: 22px;
  }
  .circleimg {
    left: 0px;
  }
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .newsletter {
    font-size: 24px;
  }
  .emailinput{
    width: 200px;
  }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
}
