@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mainimage {
  width: 100%; /* Ensure the image doesn't exceed its parent container */
  /* Maintain the image's aspect ratio */
}

.dragon {
  max-width: 10%;
  position: absolute;
  left: 5%;
  top: 48%;
}

.Butterfly {
  max-width: 8%;
  position: absolute;
  right: 9%;
  top: 24%;
}

.paatiimg {
  max-width: 30%;
  margin: auto;
  position: absolute;
  top: 15%;
  right: 0%;
}

.payment-success-fox {
  max-width: 30%;
  margin: auto;
}

.mainimagemobile {
  display: block;
  width: 100%;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fox {
  max-width: 20%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

.foxbig {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 40px;
}

#scroll {
  position: fixed;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: #000;
  text-indent: -9999px;
  display: none;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  z-index: 100000;
}
#scroll span {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -12px;
  height: 0;
  width: 0;
  border: 8px solid transparent;
  border-bottom-color: #ffffff;
}
#scroll:hover {
  background-color: #000;
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}

.mobilebanner {
  display: none;
}

/* .heroall {
  margin-top: 65px;
} */

/* Media query for screens with a maximum width of 768px (typical for mobile devices) */
/* Mobile */
@media (max-width: 767px) {
  .fox {
    max-width: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    display: none;
  }
  .MobileBanner {
    max-width: 100%;
  }
  .mobilebanner {
    display: block;
    max-width: 100%;
  }

  .mission {
    margin: auto;
  }
  .missionfox {
    margin: auto;
  }
  .mainimage {
    width: 100%; /* Make the image fill the width of the viewport */
    display: none;
  }
  .mainimagemobile {
    display: block;
    width: 100%;
  }
  .Butterfly {
    display: none;
  }
  .dragon {
    display: none;
  }
  .paatiimg {
    display: none;
  }
}

.mainimage {
  max-width: 100%; /* Ensure the image doesn't exceed its parent container */
  /* Maintain the image's aspect ratio */
}

/* .heroall {
  margin-top: 65px;
} */

/* Media query for screens with a maximum width of 768px (typical for mobile devices) */
/* Mobile */
@media (max-width: 767px) {
  .mainimage {
    width: 100%; /* Make the image fill the width of the viewport */
    display: none;
  }
  .mainimagemobile {
    display: block;
    width: 100%;
  }
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .mainimage {
    width: 100%; /* Make the image fill the width of the viewport */
  }
  .mainimagemobile {
    display: none;
  }
  .Butterfly {
    right: 7%;
    top: 35%;
  }
  .mainimagemobile {
    display: none;
    width: 100%;
  }
  .dragon {
    left: 3%;
  }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .mainimage {
    width: 100%; /* Make the image fill the width of the viewport */
  }
  .mainimagemobile {
    display: none;
  }
  .mainimagemobile {
    display: none;
    width: 100%;
  }
}

/* Larger screens */
@media only screen and (min-width: 1440px) {
  .mainimage {
    width: 100%; /* Make the image fill the width of the viewport */
  }
  .mainimagemobile {
    display: none;
  }
}

@media only screen and (min-width: 2560px) {
  .mainimage {
    width: 100%; /* Make the image fill the width of the viewport */
  }
  .mainimagemobile {
    display: none;
  }
  .Butterfly {
    right: 26%;
    max-width: 6%;
    top: 31%;
  }

  .dragon {
    max-width: 6%;
    position: absolute;
    left: 22%;
    top: 52%;
  }
}
