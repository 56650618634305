.dropdowncatergory {
  width: 300px;
  border-radius: 10px;
  /* box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1); */
  /* background-color: white; */
}

.dropdown-header {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-bottom: 40px;
}

.dropdown-header h1 {
  font-size: 24px;
  color: #000;
  font-weight: 500;
}

.dropdown-body {
  /* padding: 5px;
  border-top: 1px solid #e5e8ec; */
  display: none;
}

.dropdown-body.open {
  display: block;
  width: 90%;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 20px;
  color: #F19E38;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}
/* 
.icon.open {
  transform: rotate(90deg);
} */

.iconcheck{
  margin-right: 10px;
}

.Loadmore {
  color: #f19e38;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  margin-bottom: 12px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.refine {
  background: #f19e38;
  color: #fff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
.filter {
  background: #fff;
  border: 1px solid #f0e4f4;
  color: #636363;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  cursor: pointer;
}

.fa-plus {
  color: #f19e38;
  font-size: 12px;
  cursor: pointer;
  margin-left: 10px;
}
.fa-minus {
  color: #f19e38;
  font-size: 12px;
  cursor: pointer;
  margin-right: 10px;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .dropdowncatergory{
    width: 180px;
  }
  .dropdown-header h1{
    font-size: 18px;
  }
}
