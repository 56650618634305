@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.about {
  position: relative;
}

.spark {
  max-width: 7%;
  position: absolute;
  top: -12%;
  right: 37%;
}

.aboutus {
  margin-top: 80px;
  text-align: center;
  color: #f19e38;
  font-size: 30px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.person1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.person2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dragon {
  max-width: 10%;
  position: absolute;
  left: 5%;
  top: 45%;
}

.sethuram {
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  /* font-weight: 600; */
}

.name1 {
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.Butterfly {
  max-width: 10%;
  position: absolute;
  right: 9%;
  top: 25%;
}

.ramya {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  /* font-weight: 600; */
}

.name2 {
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 0;
}

.boy {
  max-width: 100%;
}

.About {
  padding-top: 40px;
}

.we {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.row {
  margin-top: 180px;
  text-align: center;
  position: relative;
}

.mobilewe {
  display: none;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .aboutus {
    margin-top: 0;
  }
  .we {
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    /* display: none; */
  }
  .spark {
    display: none;
  }
  .aboutus {
    font-size: 26px;
    font-weight: 600;
  }
  .person1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
  /* .name1 {
    display: none;
  } */
  .mobilewe {
    display: block;
    font-size: 22px;
    text-align: center;
    font-weight: 600;
  }
  .boy {
    max-width: 50%;
  }
  .dragon {
    max-width: 20%;
    left: 8%;
    top: 15%;
  }

  .sethuram {
    font-size: 12px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 600;
  }
  .Butterfly {
    max-width: 15%;
    right: 8%;
    top: 58%;
  }
  /* .name2 {
    display: none;
  } */
  .ramya {
    font-size: 12px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 600;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 767px) and (max-device-width: 1023px) {
  /* Styles */
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .we {
    font-size: 22px;
    display: block;
  }
  .spark {
    top: -6%;
    right: 36%;
    max-width: 8%;
  }
  .sethuram {
    font-size: 12px;
  }
  .ramya {
    font-size: 12px;
  }
  .aboutus {
    font-size: 20px;
  }
  .boy {
    max-width: 60%;
  }
  .Butterfly {
    right: 7%;
    top: 28%;
  }
  .name1 {
    font-size: 14px;
  }
  .name2 {
    font-size: 14px;
  }
  .Butterfly {
    display: none;
  }
  .dragon {
    display: none;
  }

  .aboutus {
    margin-top: 80px;
    text-align: center;
    color: #f19e38;
    font-size: 30px;
    font-weight: 500;
  }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .aboutus {
    font-size: 40px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }
  .we {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    margin-top: 25px;
    padding: 0 10%;
    line-height: 30px;
  }
  .sethuram {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0px;
  }
  .name1 {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
  }
  .name2 {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
  }
  .ramya {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }
  .Butterfly {
    display: none;
  }
  .dragon {
    display: none;
  }
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  .aboutus {
    font-size: 50px;
    font-family: "Poppins", sans-serif;
  }
  .we {
    font-size: 26px;
    font-family: "Poppins", sans-serif;
    padding: 0 10%;
  }
  .sethuram {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
  }
  .name1 {
    font-size: 22px;
    font-family: "Poppins", sans-serif;
  }
  .name2 {
    font-size: 22px;
    font-family: "Poppins", sans-serif;
  }
  .ramya {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
  }
  .Butterfly {
    display: none;
  }
  .dragon {
    display: none;
  }
}

/* @media only screen and (min-width: 1441px) and (max-width: 1600px) {
  .aboutus {
    font-size: 50px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }
  .we {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    padding: 0 10%;
    line-height: 32px;
  }
  .Butterfly {
    display: none;
  }
  .dragon {
    display: none;
  }
} */

@media only screen and (min-width: 1500px) {
  .aboutus {
    font-size: 50px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }
  .we {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    padding: 0 10%;
    line-height: 32px;
  }
  .Butterfly {
    display: none;
  }
  .dragon {
    display: none;
  }
}

@media only screen and (min-width: 2560px) {
  .aboutus {
    font-size: 80px;

    font-family: "Poppins", sans-serif;
  }
  .we {
    font-size: 32px;
    
    line-height: 80px;
    font-family: "Poppins", sans-serif;
  }
  .sethuram {
    font-size: 24px;
    font-family: "Poppins", sans-serif;
  }
  .name1 {
    font-size: 26px;
    font-family: "Poppins", sans-serif;
  }
  .name2 {
    font-size: 26px;
    font-family: "Poppins", sans-serif;
  }
  .ramya {
    font-size: 24px;
    font-family: "Poppins", sans-serif;
  }
  .dragon {
    left: 20%;
  }
  .Butterfly {
    right: 26%;
    top: 31%;
    display: block;
  }
  .Butterfly {
    display: none;
  }
  .dragon {
    display: none;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1600px) {
}

/* Media query for 14-inch laptops and similar sizes */
/* @media only screen and (min-width: 1366px) and (max-width: 1440px) { */
/* Add styles specific to 14-inch laptops */
/* } */

/* Media query for 16-inch laptops and similar sizes */
/* @media only screen and (min-width: 1441px) and (max-width: 1600px) { */
/* Add styles specific to 16-inch laptops */
/* } */
