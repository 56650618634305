@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.peopleheading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  margin-top: 5%;
}

.sparakel {
  position: absolute;
  left: 30%;
}

.peopleheading h1 {
  text-align: center;
  margin-top: 40px;
  text-align: center;
  color: #000000;
  font-size: 35px;
  font-weight: 700;
}
.peopleheading p {
  text-align: center;
}

.startupcard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.card,
.owl-item {
  transition: all 1s ease-out;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

.owl-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.cards {
  position: relative;
  height: 100vh;
  width: 100%;
  padding: 2em 0;
  background-color: #fefefe;
  margin-bottom: 15%;
}

.startupcard {
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 1em;
  margin: 1em 0;
  border-radius: 4px;
  opacity: 0.7;
  transform: scale(0.87);
  transition: transform 0.4s 0.5s ease-out, opacity 1s ease;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
  box-shadow: none;
  outline: none;
  border: none;
  position: relative;
  /* background: url(../../../public/images/Ornamentblog.svg);
  background-position: 4% 33px;
  background-size: inherit;
  background-repeat: no-repeat;
  height: inherit; */
}

.blogimg {
  max-width: 80%;
  padding: 20px;
  border-radius: 30px;
  box-shadow: #dbe3ed 0px 1px 0px;
}
.firsthalf {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blogcardimg {
  position: absolute;
}

.blogornament {
  max-width: 10%;
}

.active .startupcard {
  /* box-shadow: 0 0px 1em rgba(0, 0, 0, 0.2); */
  /* transform: box-shadow 0.3s ease, transform 0.1s 0.4s ease-in,
      opacity 0.4s ease; */
}
.card__content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  opacity: 1;
}
.active .card__content {
  opacity: 1;
  transition: opacity 0.4s ease;
}
.card__title {
  display: inline-block;
  font-size: 2em;
  overflow: hidden;
}
.card__title span {
  display: inline-block;
  -webkit-animation: slide-up 0.4s 0s ease both;
  animation: slide-up 0.4s 0s ease both;
}
.center.active .card__title span,
.center.active.cloned:last-child .card__title span {
  opacity: 1;
  -webkit-animation: slide-down 0.4s 0.4s ease both;
  animation: slide-down 0.4s 0.4s ease both;
  transition: transform 0.3s 0.4s ease, opacity 0.3s ease;
}
.center .card {
  opacity: 1;
  transform: scale(1);
}
.center .card:hover {
  /* box-shadow: 0 8px 16px -5px rgba(0, 0, 0, 0.4); */
}
.fa-quote-left {
  font-size: 60px;
  color: #fff;
}

.card__content p {
  font-size: 24px;
}

.owl-item:nth-of-type(1n) .peopelcard {
  background-color: #ff8924;
  border-radius: 30px;
  max-width: 100%;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.owl-item:nth-of-type(2n) .peopelcard {
  background-color: #ff8924;
  border-radius: 30px;
  max-width: 100%;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.owl-item:nth-of-type(3n) .peopelcard {
  background-color: #ff8924;
  border-radius: 30px;
  max-width: 100%;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.owl-item:nth-of-type(4n) .peopelcard {
  background-color: #ff8924;
  border-radius: 30px;
  max-width: 100%;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.owl-theme .owl-dots .owl-dot span {
  width: 4em;
  height: 4px;
  margin: 0 0.5em;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 0;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #ff527b;
}

.name {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.name p {
  margin-bottom: 0;
  color: #fff;
  font-size: 14px;
}

.avatar {
  max-width: 5%;
  border-radius: 50%;
  margin-right: 5px;
}
.spofity {
  max-width: 10%;
  margin-left: 10px;
}

.owl-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.owl-prev img {
  max-width: 100%;
}

.owl-next img {
  max-width: 100%;
  margin-left: 10px;
}

.author {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;
}

.author p {
  margin-bottom: 0;
  margin-right: 5px;
  font-family: "poppins";
  font-size: 14px;
}

.readmore {
  padding: 10px;
  background: #ff8924;
  color: #fff;
  border-radius: 20px;
  text-decoration: none;
  font-size: 14px;
}

.blogtitle {
  font-size: 22px;
  font-family: "poppins";
  text-align: left;
  color: #05222e;
}

.blogcontent {
  font-size: 16px;
  font-family: "poppins";
  color: #666666;
  text-align: left;
}

/* .fa-arrow-right{
  margin-right: 5px;
} */

.testimg {
  max-width: 8%;
  margin-right: 10px;
  border-radius: 50%;
}
.hinduimg {
  max-width: 20%;
  margin-right: 10px;
}

.Ornamentblog {
  max-width: 100%;
  position: absolute;
  right: 0;
  bottom: -85px;
}

.Ornamentblog2 {
  max-width: 100%;
  position: absolute;
  left: 16px;
  top: -2px;
}

.owl-pre .leftone {
  color: #fff;
  width: 50px !important;
  height: 50px !important;
  background-color: #d2d2d2;
  border-radius: 50%;
  /* padding: 11px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.owl-next .rightone {
  color: #fff;
  width: 50px;
  height: 50px;
  background-color: #d2d2d2;
  border-radius: 50%;
  /* padding: 11px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightone:hover {
  background: #fdf3e2;
  color: #f19e38;
}
.leftone:hover {
  background: #fdf3e2;
  color: #f19e38;
}
/* 
@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -120%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
} */

/* @keyframes slide-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -120%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
} */
/* @-webkit-keyframes slide-up {
  0% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -120%, 0);
  }
} */
@keyframes slide-up {
  0% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -120%, 0);
  }
}

@media only screen and (max-width: 767px) {
  .peopelcard {
    /* width: 17rem; */
    height: 15rem !important;
  }
  .fa-quote-left {
    font-size: 20px;
  }
  .name p {
    font-size: 12px;
  }
  .card__content p {
    font-size: 16px;
  }
  .spofity {
    max-width: 20%;
  }
  .avatar {
    max-width: 15%;
  }
  .owl-item:nth-of-type(1n) .peopelcard {
    border-radius: 20px;
    padding: 10px;
  }

  .owl-item:nth-of-type(2n) .peopelcard {
    border-radius: 20px;
    padding: 10px;
  }

  .owl-item:nth-of-type(3n) .peopelcard {
    border-radius: 20px;
    padding: 10px;
  }

  .owl-item:nth-of-type(4n) .peopelcard {
    border-radius: 20px;
    padding: 10px;
  }
  .peopelcard {
    padding: 2rem 0;
    height: 50vh;
  }
  .sparakel {
    left: 0;
  }
  .blogimg {
    max-width: 100%;
    margin-bottom: 10%;
  }
  .blogtitle {
    font-size: 14px;
  }
  .blogcontent {
    font-size: 12px;
  }
  .readmore {
    font-size: 12px;
  }
  .cards {
    margin-bottom: 70%;
  }
}
