@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.bookitems {
  margin-top: 50px;
  padding: 0 50px;
  position: relative;
  width: 100%;
  height: 100%;
}

.bookimg {
  /* max-width: 90%; */
  /* width: 215px; */
  max-width: 100%;
  height: 190px;
  border-radius: 10px;
}

.items {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  height: inherit;
}

.bookgenre {
  margin-top: 10px;
  color: #f19e38;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}

.bookbigtitle {
  color: #000;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.namebookauthor {
  color: #5f5f5f;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.bookprice {
  color: #000;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.bookcancel {
  color: #5f5f5f;
  font-size: 14px;
  font-family: "Poppins", sans-serif;

  text-decoration: line-through;
  margin-left: 5px;
}

.load {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadmore {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  background: #fdf3e2;
  color: #f19e38;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 10%;
  font-weight: 600;
}

.allimgicon {
  position: relative;
}

.heart {
  position: absolute;
  bottom: 15px;
  left: 8px;
  background: #fff;
  color: #f19e38;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.cartshopping {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #fff;
  color: #f19e38;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  /* font-family: "Poppins", sans-serif; */
}

.cartshopping1 {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #fdf3e2;
  color: #f19e38;
  border-radius: 10px;
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.outofstock {
  position: absolute;
  bottom: 10px; /* Adjust the distance from the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for the width of the element */
  background: #fdf3e2;
  color: #f19e38;
  border-radius: 10px;
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  white-space: nowrap; /* Prevent text from wrapping */
}

.count {
  padding: 5px;
}

.openbookitems {
  text-decoration: none;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .bookitems {
    padding: 0;
  }
  .bookimg {
    max-width: 100%;
    padding: 15px;
    border-radius: 25px;
  }
  .cartshopping {
    right: 25px;
    bottom: 25px;
    font-size: 12px;
  }
  .cartshopping1 {
    bottom: 25px;
    right: 25px;
    padding: 0px 10px;
  }
  .heart {
    font-size: 12px;
    bottom: 40px;
    left: 35px;
    font-family: "Poppins", sans-serif;
  }

  .items {
    align-items: center;
  }
  .bookbigtitle {
    text-align: center;
  }
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .bookitems {
    padding: 0;
  }
  .bookimg {
    height: 110px;
  }
  .bookbigtitle {
    font-size: 14px;
  }
  .cartshopping {
    padding: 5px;
    font-size: 16px;
  }
  .cartshopping1 {
    padding: 5px;
    font-size: 14px;
  }
  .bookgenre {
    font-size: 14px;
  }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
}
