@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.footerbg {
  background: url("../../../public/images/Footer.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 75vh;
  position: relative;
}

.footerbg_orange {
  background: url("../../../public/images/Footerbg\ \(2\).svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 75vh;
  position: relative;
}

.sub {
  background: #fdf3e2;
}

.footercontent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3%;
  flex-direction: column;
}

.footerpara1 {
  margin: 0;
  text-align: center;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: medium;
}

.socialmedia {
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 30%;
}

.socialmediaicon {
  max-width: 60%;
  margin-left: 15px;
}

.sociallink {
  display: flex;
  justify-content: center;
  align-items: center;
}

.borderbottom {
  width: 80%;
  height: 1px;
  background: #000;
  margin-top: 3%;
}

.logoicon {
  max-width: 5%;

  position: absolute;
  bottom: 15rem;
}

.and {
  margin-right: 5px !important;
}

.links {
  margin-top: 30px;
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.links a {
  font-size: 14px;
  color: #000;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  font-weight: 500;
}

.copyright {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #000;
  padding-top: 60px;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .footerbg {
    height: 100%;
    background-color: #ff8924;
    background-size: auto;
  }
  .footerbg_orange {
    height: 100%;
    background-color: #ff8924;
    background-size: auto;
  }
  .links {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .copyright {
    padding-top: 0;
    font-size: 10px;
    margin-bottom: 5px;
  }
  .links a {
    font-size: 12px;
  }
  .socialmediaicon {
    max-width: 100%;
    margin-left: 10px;
    margin-right: 22px;
  }
  .socialmedia {
    margin-top: none;
  }
  .logoicon {
    max-width: 10%;
    left: 0;
    bottom: 120px;
  }
  .footerpara1 {
    font-size: 10px;
  }
  .socialmedia {
    padding-top: 10px;

    margin-top: 1rem;
  }
  .footercontent {
    padding-bottom: 5%;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 979px) {
  .footerbg {
    height: 100% !important;
  }
  .footercontent {
    padding-top: 15%;
    padding-bottom: 10%;
  }
  .logoicon {
    max-width: 5%;
    position: absolute;
    bottom: 12rem;
  }
  .socialmediaicon {
    max-width: 80%;
    margin-left: 15px;
  }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .footercontent {
    padding-top: 5%;
  }
  .logoicon {
    bottom: 55%;
  }
  .socialmediaicon {
    max-width: 80%;
  }
}

/* Larger screens */
@media only screen and (min-width: 1440px) {
  .footercontent {
    padding-top: 5%;
  }
  .logoicon {
    bottom: 50%;
  }
}

@media (min-width: 2048px) and (max-width: 4096px) {
  .socialmediaicon {
    max-width: 100%;
  }
  .links a {
    font-size: 30px !important;
  }
  .copyright{
    font-size: 26px;
  }
  .footerpara1{
    font-size: 30px;
  }
  .borderbottom{
    margin-top: 4%;
  }
}
