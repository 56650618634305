@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.listcard {
  margin-top: 40px;
}

.listview {
  margin: auto;
  margin-bottom: 80px;
}

.list1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vadaiimage {
  max-width: 90%;
  width: 100%;
  border-radius: 20px;
  margin: auto;
}

.Humorous {
  padding: 10px;
  background: #fdf3e2;
  color: #f19e38;
  font-size: 16px;
  display: inline;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.vadai {
  margin-top: 20px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.bookram {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #a1a1a1;
}

.bookramdec {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #000;
  margin-bottom: 0;
  text-align: left;
}

.amount {
  font-size: 14px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #000;
}

.amountstrickout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.openbookitems {
  text-decoration: none;
}

.strickout {
  color: #5f5f5f;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  text-decoration: line-through;
  margin-right: 10px;
}

.percentage {
  color: #fff;
  background-color: #f19e38;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  padding: 0px 10px;
  border-radius: 5px;
}

.list3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.checkout {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart {
  background: #fff;
  color: #f19e38;
  padding: 10px;
  font-size: 14px;
  border-radius: 50%;
  margin-right: 20px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  cursor: pointer;
}

.cartshopping {
  /* position: absolute; */
  bottom: 15px;
  right: 34px;
  color: #f19e38;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: 1px solid #f19e38;
}

.heart {
  padding: 10px;
  color: #fff;
}

.wishlist {
  background-color: #f19e38;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  padding: 0 10px;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .vadaiimage {
    padding: 0px;
    width: 200px;
    height: 150px;
  }
  .Humorous {
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
  }
  .list2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 0;
  }
  .list3{
    padding: 0;
  }
  .bookramdec {
    text-align: center;
  }
  .vadai {
    text-align: center;
    font-size: 12px;
  }
  .listview{
    padding: 0;
  }
  .list1{
    padding: 0;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
}
