.rating {
  width: 100px;
}

.peopleheading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
}

.sparakel {
  position: absolute;
  left: 30%;
}

.peopleheading h1 {
  text-align: center;
  margin-top: 60px;
  text-align: center;
  color: #000000;
  font-size: 35px;
  font-weight: 700;
}
.peopleheading p {
  text-align: center;
}

.card,
.owl-item {
  transition: all 1s ease-out;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

.owl-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.cards {
  position: relative;
  /* height: 100vh; */
  width: 100%;
  padding: 2em 0;
  background-color: #fefefe;
}

.card {
  display: flex;
  justify-content: center;
  height: 19em;
  padding: 1em;
  margin: 1em 0;
  border-radius: 4px;
  opacity: 0.7;
  transform: scale(0.87);
  transition: transform 0.4s 0.5s ease-out, opacity 1s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.active .card {
  /* box-shadow: 0 0px 1em rgba(0, 0, 0, 0.2);
  transform: box-shadow 0.3s ease, transform 0.1s 0.4s ease-in,
    opacity 0.4s ease; */
}
.card__content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  color: #fff;
  opacity: 1;
}
.active .card__content {
  opacity: 1;
  transition: opacity 0.4s ease;
}
.card__title {
  display: inline-block;
  font-size: 2em;
  overflow: hidden;
}
.card__title span {
  display: inline-block;
  -webkit-animation: slide-up 0.4s 0s ease both;
  animation: slide-up 0.4s 0s ease both;
}
.center.active .card__title span,
.center.active.cloned:last-child .card__title span {
  opacity: 1;
  -webkit-animation: slide-down 0.4s 0.4s ease both;
  animation: slide-down 0.4s 0.4s ease both;
  transition: transform 0.3s 0.4s ease, opacity 0.3s ease;
}
.center .card {
  opacity: 1;
  transform: scale(1);
}
.center .card:hover {
  /* box-shadow: 0 8px 16px -5px rgba(0, 0, 0, 0.4); */
}
.fa-quote-left {
  font-size: 60px;
  color: #fff;
}

.nameofpeople {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.card__content p {
  font-size: 16px;
}

.owl-item:nth-of-type(1n) .peopelcard {
  background-color: #ff8924;
  border-radius: 30px;
  max-width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.owl-item:nth-of-type(2n) .peopelcard {
  background-color: #ff8924;
  border-radius: 30px;
  max-width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.owl-item:nth-of-type(3n) .peopelcard {
  background-color: #ff8924;
  border-radius: 30px;
  max-width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.owl-item:nth-of-type(4n) .peopelcard {
  background-color: #ff8924;
  border-radius: 30px;
  max-width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.owl-theme .owl-dots .owl-dot span {
  width: 4em;
  height: 4px;
  margin: 0 0.5em;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 0;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #ff527b;
}

.name {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.name p {
  margin-bottom: 0;
  color: #fff;
  font-size: 14px;
}

.icon {
  display: flex;
  flex-direction: row;
}

.avatar {
  max-width: 5%;
  border-radius: 50%;
  margin-right: 10px;
}
.spofity {
  max-width: 10%;
  margin-left: 10px;
}

.owl-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.owl-prev img {
  max-width: 100%;
}

.owl-next img {
  max-width: 100%;
  margin-left: 10px;
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -120%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -120%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}
@-webkit-keyframes slide-up {
  0% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -120%, 0);
  }
}
@keyframes slide-up {
  0% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -120%, 0);
  }
}

@media only screen and (max-width: 767px) {
  .peopelcard {
    /* width: 17rem; */
    height: 16rem !important;
  }
  .fa-quote-left {
    font-size: 20px;
  }
  .name p {
    font-size: 8px;
  }
  .card__content p {
    font-size: 10px;
  }
  .spofity {
    max-width: 20%;
  }
  .avatar {
    max-width: 15%;
  }
  .owl-item:nth-of-type(1n) .peopelcard {
    border-radius: 20px;
    padding: 10px;
  }

  .owl-item:nth-of-type(2n) .peopelcard {
    border-radius: 20px;
    padding: 10px;
  }

  .owl-item:nth-of-type(3n) .peopelcard {
    border-radius: 20px;
    padding: 10px;
  }

  .owl-item:nth-of-type(4n) .peopelcard {
    border-radius: 20px;
    padding: 10px;
  }
  .peopelcard {
    padding: 2rem 0;
    height: 50vh;
  }
  .sparakel {
    left: 0;
  }
  .peopleheading h1 {
    font-size: 24px;
  }
  .peopleheading p {
    font-size: 18px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .sparakel {
    position: absolute;
    left: 19%;
  }
}

@media (min-width: 2048px) and (max-width: 4096px){
  .peopleheading h1{
    font-size: 50px;
  }
  .peopleheading p{
    font-size: 26px;
  }
  .card__content p {
    font-size: 30px;
  }
  .nameofpeople p {
    font-size: 24px;
    font-weight: bold;
  }
  

}
