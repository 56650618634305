.profile {
  margin-top: 0%;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .profile {
    margin-top: 10%;
  }
}
