@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.Journey {
  position: relative;
}

.spark {
  max-width: 8%;
  position: absolute;
  top: -10%;
  left: 35%;
}

.Journeyus {
  margin-top: 80px;
  text-align: center;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.person1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.person2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.person3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Squiril {
  max-width: 55%;
}

.house {
  max-width: 100%;
}

.Well {
  max-width: 80%;
}

.ant {
  max-width: 25%;
  position: absolute;
  bottom: 0%;
  right: 28%;
}

.sethuram {
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  /* font-weight: 600; */
}

.name1 {
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.Butterfly {
  max-width: 10%;
  position: absolute;
  right: 9%;
  top: 25%;
}

.ramya {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  /* font-weight: 600; */
}

.name2 {
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 0;
}

.boy {
  max-width: 100%;
}

.About {
  padding-top: 40px;
  
}
/* .AntJourney{
  margin-left: 43px;
} */

/* @media (min-width: 1024px) {
  
  .us {
    height: 50px;
  }
} */

.Journeywe {
  text-align: center;
  margin: 0 auto;
  width: 50%;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  font-family: "Poppins", sans-serif;
}

.row {
  margin-top: 180px;
  text-align: center;
  position: relative;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .Journeyus {
    font-size: 24px;
  }
  .Journeywe {
    font-size: 14px;
    padding: 10px;
    /* text-align: center; */
    display: block;
    text-align: center;
    width: 88%;
  }
  .person1 {
    display: none;
  }
  .person2 {
    padding-bottom: 30px;
  }
  .ant {
    max-width: 60%;
    right: 0px;
    bottom: 15px;
  }
  .person3 {
    display: none;
  }
  .Well {
    max-width: 30%;
    position: absolute;
    right: 0px;
    top: 158px;
  }
  .well1 {
    background: url("../../public/images/Well 2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    height: 120px;
    display: none;
  }
  .spark {
    max-width: 15% !important;
    position: absolute !important;
    top: -30px !important;
    left: 60px !important;
  }
  .About {
    padding-top: 0px;
  }
}

/* Tablet */
/* @media only screen and (min-width: 768px) and (max-width: 1023px) {
  
} */

/* Laptop */
@media only screen and (max-width: 1500px) {
  .spark {
    max-width: 6%;
    position: absolute;
    top: -10%;
    left: 38%;
  }
}

@media (min-width: 2048px) and (max-width: 4096px){
  .Journeyus{
    font-size: 50px;
  }
  .Journeywe{
    font-size: 26px;
  }
}

/* Larger screens */
/* @media only screen and (min-width: 1366px) {
} */
