@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.Values {
  background: url("../../public/images/Values1.png");
  background-position: 0px 80%;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100%;
  height: 86rem;
  margin-bottom: -5%;
  z-index: 999;
}

.valuesheading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.valuetitle {
  font-size: 32px;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  position: relative;
}

.subtitle {
  text-align: center;
  font-size: 16px;
  color: #4d4d4d;
}

.spark {
  max-width: 30%;
  position: absolute;
  top: -170%;
  right: -24%;
}

.greenleaf {
  margin: auto;
  height: 450px;
  margin-top: 60px;
}

.firsthalf1 {
  background: url("../../public/images/green3.svg");
  /* background-position: 10px 41px; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 100px;
  text-align: center;
  height: inherit;
  font-family: "Poppins", sans-serif;
}
.firsthalf2 {
  background: url("../../public/images/green3.svg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 100px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  height: inherit;
}

.firsthalf3 {
  background: url("../../public/images/green3.svg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 100px;
  text-align: center;
  height: inherit;
  font-family: "Poppins", sans-serif;
}
.firsthalf4 {
  background: url("../../public/images/green3.svg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 100px;
  text-align: center;
  height: inherit;
  font-family: "Poppins", sans-serif;
}

.valuestitles1 {
  font-size: 26px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  padding-top: 60px;
  padding-left: 10px;
  font-weight: 600;
}

.valuessubcontent1 {
  font-size: 14px;
  color: #fff;
  padding: 0px 110px;
  font-family: "Poppins", sans-serif;
}
.valuestitles2 {
  font-size: 26px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  padding-top: 40px;
  font-weight: 600;
}

.valuessubcontent2 {
  font-size: 14px;
  color: #fff;
  padding: 0px 110px;
  font-family: "Poppins", sans-serif;
}
.valuestitles3 {
  font-size: 26px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  padding-top: 0px;
  font-weight: 600;
}

.valuessubcontent3 {
  font-size: 14px;
  color: #fff;
  padding: 0px 110px;
  font-family: "Poppins", sans-serif;
}
.valuestitles4 {
  font-size: 26px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  padding-top: 40px;
  font-weight: 600;
}

.valuessubcontent4 {
  font-size: 14px;
  color: #fff;
  padding: 0px 115px;
  font-family: "Poppins", sans-serif;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  /* .Values {
    height: 125rem;
  } */
  .Values {
    background: url("../../public/images/spiderweb.svg");
    background-position: 0px 80%;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 100%;
    height: 125rem;
    margin-bottom: 0%;
  }
  .valuesheading {
    padding-top: 5rem;
    padding-bottom: 0;
  }
  .valuetitle {
    font-size: 24px;
  }
  .subtitle {
    font-size: 14px;
  }
  .firsthalf1 {
    max-width: 100%;
    padding-left: 20px;
    background-position: right;
    background-size: cover;
  }
  /* .greenleaf {
    padding: 0;
  } */
  .valuestitles1 {
    font-size: 16px;
    text-align: left;
    padding-top: 10px;
    padding-left: 0;
  }
  .valuessubcontent1 {
    font-size: 14px;
    text-align: left;
    padding: 0;
    margin-right: 100px;
  }
  .firsthalf2 {
    max-width: 100%;
    padding-right: 10px;
    background-position: left;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .valuestitles2 {
    font-size: 16px;
    text-align: right;
    padding-top: 10px;
  }
  .valuessubcontent2 {
    font-size: 14px;
    text-align: right;
    padding: 0;
    margin-left: 100px;
  }
  .firsthalf3 {
    max-width: 100%;
    padding-left: 20px;
    background-position: right;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .valuestitles3 {
    font-size: 16px;
    text-align: left;
    padding-top: 10px;
  }
  .valuessubcontent3 {
    font-size: 12px;
    text-align: left;
    padding: 0;
    margin-right: 100px;
  }
  .firsthalf4 {
    max-width: 100%;
    padding-right: 20px;
    background-position: left;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .valuestitles4 {
    font-size: 16px;
    text-align: right;
    padding-top: 10px;
  }
  .valuessubcontent4 {
    font-size: 14px;
    text-align: right;
    padding: 0;
    margin-left: 100px;
  }
  .spark {
    max-width: 30%;
    position: absolute;
    top: -150%;
    right: -24%;
  }
  .valuetitle {
    font-size: 20px;
  }
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .firsthalf1 {
    max-width: 100%;
    padding-left: 20px;
    background-position: right;
    background-size: cover;
  }
  /* .greenleaf {
    padding: 0;
  } */
  .valuestitles1 {
    font-size: 16px;
    text-align: left;
    padding-top: 10px;
    padding-left: 0;
  }
  .valuessubcontent1 {
    font-size: 14px;
    text-align: left;
    padding: 0;
    margin-right: 100px;
  }
  .firsthalf2 {
    max-width: 100%;
    padding-right: 10px;
    background-position: left;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .valuestitles2 {
    font-size: 16px;
    text-align: right;
    padding-top: 10px;
  }
  .valuessubcontent2 {
    font-size: 14px;
    text-align: right;
    padding: 0;
    margin-left: 100px;
  }
  .firsthalf3 {
    max-width: 100%;
    padding-left: 20px;
    background-position: right;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .valuestitles3 {
    font-size: 16px;
    text-align: left;
    padding-top: 10px;
  }
  .valuessubcontent3 {
    font-size: 12px;
    text-align: left;
    padding: 0;
    margin-right: 100px;
  }
  .firsthalf4 {
    max-width: 100%;
    padding-right: 20px;
    background-position: left;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .valuestitles4 {
    font-size: 16px;
    text-align: right;
    padding-top: 10px;
  }
  .valuessubcontent4 {
    font-size: 14px;
    text-align: right;
    padding: 0;
    margin-left: 100px;
  }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .firsthalf1 {
    background-position: 0px 20px;
  }
  .Values {
    background-position: -60px -75px;
    height: 80rem;
  }
  .valuestitles1 {
    font-size: 18px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding-top: 10px;
    padding-left: 10px;
    font-weight: 600;
  }
  .valuestitles2 {
    font-size: 18px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding-top: 35px;
    font-weight: 600;
  }
  .valuestitles3 {
    font-size: 18px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding-top: 20px;
    font-weight: 600;
    /* width: 217px; */
  }
  .valuestitles4 {
    font-size: 18px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding-top: 0px;
    font-weight: 600;
  }
}

/* Larger screens */
@media only screen and (min-width: 1440px) {
  .valuestitles1 {
    font-size: 20px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding-top: 10px;
    padding-left: 10px;
    font-weight: 600;
  }
  .valuestitles2 {
    font-size: 20px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding-top: 35px;
    font-weight: 600;
  }
  .valuestitles3 {
    font-size: 20px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding-top: 20px;
    font-weight: 600;
    /* width: 217px; */
  }
  .valuestitles4 {
    font-size: 20px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding-top: 0px;
    font-weight: 600;
  }
  .valuesheading {
    padding-top: 0rem;
  }
  .Values {
    height: 85rem;
    background-position: 0 -150px;
  }
  .valuessubcontent1 {
    font-size: 16px;
  }
  .valuessubcontent2 {
    font-size: 16px;
  }
  .valuessubcontent3 {
    font-size: 16px;
  }
  .valuessubcontent4 {
    font-size: 16px;
  }
  .valuetitle {
    font-size: 40px;
  }
  .firsthalf1 {
    background-position: 10px !important;
    padding: 0 50px;
  }
  .firsthalf2 {
    background-position: 10px !important;
    padding: 0 50px;
  }
  .firsthalf3 {
    background-position: 10px !important;
    padding: 0 50px;
  }
  .firsthalf4 {
    background-position: 10px !important;
    padding: 0 50px;
  }
  .items .button-container {
    top: 50px;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1200px) {
  .valuestitles1 {
    font-size: 13px;
  }
  .valuessubcontent1 {
    font-size: 11px;
  }
  .valuestitles2 {
    font-size: 13px;
  }
  .valuessubcontent2 {
    font-size: 11px;
  }
  .valuestitles3 {
    font-size: 13px;
  }
  .valuessubcontent3 {
    font-size: 11px;
  }
  .valuestitles4 {
    font-size: 13px;
  }
  .valuessubcontent4 {
    font-size: 11px;
  }
}
@media only screen and (min-width: 1500px) {
  .valuesheading {
    padding-top: 7rem !important;
    padding-bottom: 5rem;
  }
  .valuessubcontent1 {
    padding: 0px 135px;
  }
  .valuessubcontent2 {
    padding: 0px 135px;
  }
  .valuessubcontent3 {
    padding: 0px 135px;
    margin-bottom: 0;
  }
  .valuessubcontent4 {
    padding: 0px 135px;
  }

  .valuestitles1 {
    padding-top: 0px;
    font-size: 20px;
  }
  .valuestitles2 {
    /* padding-top: 0px; */
    font-size: 20px;
  }
  .valuestitles3 {
    padding-top: 20px;
    font-size: 20px;
  }
  .valuestitles4 {
    padding-top: 10px;
    font-size: 20px;
  }
}
@media only screen and (min-width: 1501px) and (max-width: 1600px) {
  .Values {
    background-position: 0 -70px;
  }
}
@media (min-width: 2048px) and (max-width: 4096px) {
  .Learning {
    padding: 20%;
  }
  .Values {
    height: 100rem;
  }
  .valuetitle {
    font-size: 70px;
  }
  .subtitle {
    font-size: 30px;
  }
  .firsthalf1 {
    background-position: 10px !important;
    background-size: 100% 100%;
    max-width: 100% !important;
  }
  .firsthalf2 {
    background-position: 10px !important;
    background-size: 100% 100%;
    padding: 0 50px;
  }
  .firsthalf3 {
    background-position: 10px !important;
    background-size: 100% 100%;
    padding: 0 50px;
  }
  .firsthalf4 {
    background-position: 10px !important;
    background-size: 100% 100%;
    padding: 0 50px;
  }
}
