@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.one {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.author {
  position: absolute;
  top: -20px;
  left: 393px;
  max-width: 3%;
}

/* .container {
  justify-content: center; 
  align-items: center;
  margin-right: 50px;
} */

/* Card.css */

.cardauthor {
  width: 300px;
  height: 350px;
  position: relative;
  overflow: hidden;
  background-image: url("../../public/images/Bhavya.jpg"); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 30px;
  margin-top: 10px;
  margin: auto;
}

.cardauthor:hover {
  transform: scale(1.05); /* Scale up the card on hover */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Add a shadow on hover */
  filter: grayscale(1);
}

.cardauthor::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    transparent 0%,
    rgba(47, 43, 43, 0.9) 100%
  );
  opacity: 0; /* Initially hide the gradient overlay */
  transition: opacity 0.3s ease; /* Smoothly show/hide the gradient overlay */
}

.cardauthor:hover::before {
  opacity: 1; /* Show the gradient overlay on hover */
}

.card-content {
  position: absolute;
  bottom: -100%; /* Initially, move the content below the card */
  left: 0;
  width: 100%;
  padding: 20px;
  color: #fff;
  max-height: 180px; /* Set a fixed height for the scrollable content */
  transition: bottom 0.3s ease; /* Smoothly slide the content up on hover */
}

.cardauthor:hover .card-content {
  bottom: 50% !important; /* Slide the content up on hover */
}

.authorcontent {
  max-height: 300px;
  overflow-y: scroll;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}
.authorcontent::-webkit-scrollbar {
  display: none;
}

h2 {
  margin: 0;
  font-size: 20px;
}

/* p {
  margin: 10px 0;
  font-size: 18px;

  overflow: hidden;
  text-overflow: ellipsis;
} */

.col-sm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Add any additional styles you need */

.card1 {
  width: 300px;
  height: 350px;
  position: relative;
  overflow: hidden;
  background-image: url("../../public/images/steffi.svg"); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 30px;
  margin-top: 10px;
  margin: auto;
}

.card1:hover {
  transform: scale(1.05); /* Scale up the card on hover */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Add a shadow on hover */
  filter: grayscale(1);
}

.card1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    transparent 0%,
    rgba(47, 43, 43, 0.9) 100%
  );
  opacity: 0; /* Initially hide the gradient overlay */
  transition: opacity 0.3s ease; /* Smoothly show/hide the gradient overlay */
}

.card1:hover::before {
  opacity: 1; /* Show the gradient overlay on hover */
}

.card1:hover .card-content {
  bottom: 50% !important; /* Slide the content up on hover */
}

.Addmore {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Author {
  background: #f19e38;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  padding: 10px;
  border-radius: 30px;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mushroom {
  width: 70%;
  height: auto;
  align-items: center;
  margin-top: 170px;
}

.card2 {
  width: 300px;
  height: 350px;
  position: relative;
  overflow: hidden;
  background-image: url("../../public/images/Ida\ Bernice.jpg"); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 30px;
  margin-top: 10px;
  margin: auto;
}

.card2:hover {
  transform: scale(1.05); /* Scale up the card on hover */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Add a shadow on hover */
  filter: grayscale(1);
}

.card2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    transparent 0%,
    rgba(47, 43, 43, 0.9) 100%
  );
  opacity: 0; /* Initially hide the gradient overlay */
  transition: opacity 0.3s ease; /* Smoothly show/hide the gradient overlay */
}

.card2:hover::before {
  opacity: 1; /* Show the gradient overlay on hover */
}

.card2:hover .card-content {
  bottom: 50% !important; /* Slide the content up on hover */
}

.rama {
  width: 300px;
  height: 350px;
  position: relative;
  overflow: hidden;
  background-image: url("../../public/images/rama.svg"); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 30px;
  margin-top: 10px;
  margin: auto;
}

.rama:hover {
  transform: scale(1.05); /* Scale up the card on hover */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Add a shadow on hover */
  filter: grayscale(1);
}

.rama::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    transparent 0%,
    rgba(47, 43, 43, 0.9) 100%
  );
  opacity: 0; /* Initially hide the gradient overlay */
  transition: opacity 0.3s ease; /* Smoothly show/hide the gradient overlay */
}

.rama:hover::before {
  opacity: 1; /* Show the gradient overlay on hover */
}

.rama:hover .card-content {
  bottom: 50% !important; /* Slide the content up on hover */
}
.niranjan {
  width: 300px;
  height: 350px;
  position: relative;
  overflow: hidden;
  background-image: url("../../public/images/Niranjan.jpg"); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 30px;
  margin-top: 10px;
  margin: auto;
}

.niranjan:hover {
  transform: scale(1.05); /* Scale up the card on hover */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Add a shadow on hover */
  filter: grayscale(1);
}

.niranjan::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    transparent 0%,
    rgba(47, 43, 43, 0.9) 100%
  );
  opacity: 0; /* Initially hide the gradient overlay */
  transition: opacity 0.3s ease; /* Smoothly show/hide the gradient overlay */
}

.niranjan:hover::before {
  opacity: 1; /* Show the gradient overlay on hover */
}

.niranjan:hover .card-content {
  bottom: 50% !important; /* Slide the content up on hover */
}
.vaishali {
  width: 300px;
  height: 350px;
  position: relative;
  overflow: hidden;
  background-image: url("../../public/images/Vaishali_Author.jpg"); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 30px;
  margin-top: 10px;
  margin: auto;
}

.vaishali:hover {
  transform: scale(1.05); /* Scale up the card on hover */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Add a shadow on hover */
  filter: grayscale(1);
}

.vaishali::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    transparent 0%,
    rgba(47, 43, 43, 0.9) 100%
  );
  opacity: 0; /* Initially hide the gradient overlay */
  transition: opacity 0.3s ease; /* Smoothly show/hide the gradient overlay */
}

.vaishali:hover::before {
  opacity: 1; /* Show the gradient overlay on hover */
}

.vaishali:hover .card-content {
  bottom: 50% !important; /* Slide the content up on hover */
}
.Gowthami {
  width: 300px;
  height: 350px;
  position: relative;
  overflow: hidden;
  background-image: url("../../public/images/Gowthami_Illustrator.jpg"); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 30px;
  margin-top: 10px;
  margin: auto;
}

.Gowthami:hover {
  transform: scale(1.05); /* Scale up the card on hover */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Add a shadow on hover */
  filter: grayscale(1);
}

.Gowthami::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    transparent 0%,
    rgba(47, 43, 43, 0.9) 100%
  );
  opacity: 0; /* Initially hide the gradient overlay */
  transition: opacity 0.3s ease; /* Smoothly show/hide the gradient overlay */
}

.Gowthami:hover::before {
  opacity: 1; /* Show the gradient overlay on hover */
}

.Gowthami:hover .card-content {
  bottom: 50% !important; /* Slide the content up on hover */
}

.flower1 {
  margin-top: 190px;
}

.flower2 {
  margin-top: 150px;
}

.card3 {
  width: 300px;
  height: 350px;
  position: relative;
  overflow: hidden;
  background-image: url("../../public/images/ramya.svg"); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 30px;
  margin-top: 10px;
  margin: auto;
}

.card3:hover {
  transform: scale(1.05); /* Scale up the card on hover */
  box-shadow: 0 0 20px rgba(13, 12, 12, 0.907); /* Add a shadow on hover */
  filter: grayscale(1);
}

.card3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    transparent 0%,
    rgba(47, 43, 43, 0.9) 100%
  );
  opacity: 0; /* Initially hide the gradient overlay */
  transition: opacity 0.3s ease; /* Smoothly show/hide the gradient overlay */
}

.card3:hover::before {
  opacity: 1; /* Show the gradient overlay on hover */
}

.card3:hover .card-content {
  bottom: 50% !important; /* Slide the content up on hover */
}

.card4 {
  width: 300px;
  height: 350px;
  position: relative;
  overflow: hidden;
  background-image: url("../../public/images/sethu.svg"); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 30px;
  margin-top: 10px;
  margin: auto;
}

.card4:hover {
  transform: scale(1.05); /* Scale up the card on hover */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Add a shadow on hover */
  filter: grayscale(1);
}

.card4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    transparent 0%,
    rgba(47, 43, 43, 0.9) 100%
  );
  opacity: 0; /* Initially hide the gradient overlay */
  transition: opacity 0.3s ease; /* Smoothly show/hide the gradient overlay */
}

.card4:hover::before {
  opacity: 1; /* Show the gradient overlay on hover */
}

.card4:hover .card-content {
  bottom: 50% !important; /* Slide the content up on hover */
}

.flower3 {
  margin-top: 140px;
  width: 360px;
  margin-right: 40px;
  transform: rotate(-15deg); /* Rotate the image by 45 degrees */
}
.two {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  width: 102%;
  font-family: "Poppins", sans-serif;
}

.vai {
  font-size: 30px;
  font-weight: 700;
  margin-top: 60px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.author-overall {
  position: relative;
  margin-bottom: 5%;
}

.two {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

@media (max-width: 768px) {
  .flower1,
  .flower2,
  .flower3,
  .mushroom,
  .hidden-on-mobile {
    display: none;
  }
  .Author {
    width: 30%;
  }

  /* .card,
  .card1,
  .card2,
  .card3,
  .card4 {
    width: 300px;
    height: 350px !important;
  } */

  .vai {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
  }

  .one {
    width: 100%;
    font-size: 24px;
    margin-top: 20px;
    font-family: "Poppins", sans-serif;
  }
  .author {
    position: absolute;
    top: -1%;
    left: 31%;
    max-width: 10%;
  }

  .two {
    font-size: 14px;
  }
  .font {
    font-size: 14px;
  }
  .authorcontent {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .author-overall {
    margin-top: 5%;
  }
  .author {
    position: absolute;
    top: -3%;
    left: 38%;
    max-width: 6%;
  }
}
/* tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .flower1,
  .flower2,
  .flower3,
  .mushroom {
    display: none;
  }
  .Author {
    width: 15%;
  }
  .author {
    position: absolute;
    top: -2%;
    left: 37%;
    max-width: 8%;
  }
}

@media only screen and (min-width: 1500px) {
  .author-overall {
    margin-top: 5%;
  }
  .author {
    position: absolute;
    top: -50px;
    left: 40%;
    max-width: 5%;
  }
}

@media (min-width: 2048px) and (max-width: 4096px) {
  .card1,
  .card2,
  .card3,
  .card4,
  .cardauthor {
    margin: 0;
    width: 400px;
    height: 550px;
  }
  .one {
    font-size: 70px;
  }
  .two {
    font-size: 30px;
  }
  .Author {
    font-size: 30px;
  }

}
